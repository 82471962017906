/* Global styles */
.main-login {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #656f75;
    opacity: 0.8;
}

.container-login {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: stretch;
    padding: 5%;
}

/* Left side (Food) */
.left-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #c8cfcb;
    align-items: center;
    justify-content: center;
    border-radius: 5%;


}

.login-title {
    font-size: 35px;
    font-weight: lighter;
}

.login-description {
    padding: 3%;
    font-size: 20px;
}

/* Right side (Login form) */
.right-side {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    opacity: 0.8;
    border-radius: 5%;
}

.login-form {
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.Form.Group {
    margin-bottom: 15px;
}

.login-title-label {
    margin: 10% 0%;
    font-size: 20px;
    font-weight: bold;
    color: black;

}

.login-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.login-password {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

login-button {
    width: 100%;
    padding: 10px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}