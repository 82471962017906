@media print {
    @page {
        size: A4;
        margin: 1.5;
    }

    body {
        height: 100%;
        border: 2px solid black;
    }

    .print-hide {
        display: none;
    }

}

/* .container {
    display: flex;
    flex-direction: column;
} */

.view-container {
    display: flex;
    flex-direction: column;
}

.label-receipt {
    display: flex;
    justify-content: center;
}

.date-receipt {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.view-container-select {
    display: flex;
    flex-direction: row;
}

.btn {
    margin-top: 15px;
    width: 50%;
}

.cusdetails {
    margin: 10px;
}

thead {
    background-color: gray;
    color: aliceblue;
    font-family: sans-serif;
}

.div-amount {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* justify-content: flex-end; */
}

.amount {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: fit-content;
}

.input-recd,
.input-recd-number {
    border: 1px solid black;
}

.select-source .form-check-input {
    /* border-width: 5px; */
    border-color: black;
}