@media print {
    @page {
        size: A4;
        margin: 1.5;
    }

    body {
        height: 100%;
        border: 2px solid black;
    }
}

.box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.horizontal-direction {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.title {
    display: inline-flex;
    justify-content: center;
    margin-bottom: 3%;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

/* .cdetails {
    display: flex;
    flex-direction: row-reverse;
    border: 2px solid black;
} */

.cstdetails {
    display: flex;
    justify-content: space-between;

}

/* .header-row {
    background-color: grey;
    color: white;
} */
table {
    margin-top: 5%;
    width: 100%;
    margin-bottom: 5%;
}

/* table,
thead,
tbody {
    border-color: gray;
} */

.total-amount {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
}

.label,
.price {
    display: flex;
    flex-direction: column;
    margin-right: 3%;
}