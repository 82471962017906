@media print {
    @page {
        size: A4;
        margin: 1.5;
    }

    body {
        height: 100%;
        border: 2px solid black;
    }


    .print-hide,
    .download-table-xls-button {
        display: none;
    }

}

body {
    height: 100%;
}

.title {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin: 3%;
}

.report-body {
    height: 100%;
    margin: 10%;
    margin-top: 3%;
}

/* .first-ele {
    justify-items: flex-start;
} */

.operator {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.option {
    display: flex;
    flex-direction: row;
}

.button-go {
    width: 40px;
    height: 40px;
}

.download-table-xls-button {
    background-color: rgb(81, 81, 189);
    color: white;
    border-radius: 5px;
    width: 90px;
}

.spacing {
    display: flex;
    margin: 1%;
    width: 150px;
    height: 50px;
}

thead,
tbody {
    border: 1px solid black;
}

/* tr,
td,
th {
    width: 60px;
} */

.index {
    width: 20px;
}

.name,
.issue_to {
    width: 100px;
}

.invoice {
    width: 60px;
}

.date,
.recd {
    width: 100px;
}