.form-div-create {
    display: flex;
    justify-content: center;
    margin: 35px;
}

.form-create {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border: 1px solid black;
    padding: 50px;
    align-items: center;
    border-radius: 13px;
    background-color: gray;
    color: white;

}

.submit {
    display: flex;
    align-items: center;
    width: fit-content !important;

}

.input-recd {
    border: 1px solid black;
}